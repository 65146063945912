const regex = /(?:<a.*?href=["'])(.*?)(?:["'])/gi;

export const convertLinkProperties = (html: string, getLinkProps: Function) => {
  const replaces = [];
  let match;

  do {
    match = regex.exec(html);
    if (match) {
      const [fullMatch, groupMatch] = match;
      replaces.push([
        fullMatch,
        fullMatch.replace(groupMatch, getLinkProps(groupMatch).href),
      ]);
    }
  } while (match);

  return replaces.reduce(
    (a, [matcho, replace]) => a.replace(matcho, replace),
    html,
  );
};
