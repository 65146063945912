import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { CorvidTypes } from '@wix/editor-elements-types';
import { tpaGalleryPropsSDKFactory } from '../../TPAGallery/corvid/TPAGallerySDK';
import { tpaGalleryCurrentItemSDKFactory } from '../../TPAGallery/corvid/TPAGalleryCurrentItemSDK';
import { tpaGalleryPlayableSDKFactory } from '../../TPAGallery/corvid/TPAGalleryPlayableSDK';
import { tpaGalleryNavigationButtonsSDKFactory } from '../../TPAGallery/corvid/TPAGalleryNavigationButtonsSDK';
import {
  TPAGallerySDK,
  TPAGalleryProps,
  TPAGalleryCapabilitiesSDK,
} from '../../TPAGallery/TPAGallery.types';

export const ownSDKFactory: CorvidTypes.CorvidSDKFactory<TPAGalleryCapabilitiesSDK> = () => ({
  get galleryCapabilities() {
    return {
      isPlayable: true,
      hasCurrentItem: true,
      hasNavigationButtons: true,
      supportsAllMediaTypes: false,
      isAnimatable: true,
    };
  },
});

export const sdk = composeSDKFactories<TPAGalleryProps, TPAGallerySDK>(
  tpaGalleryPropsSDKFactory,
  tpaGalleryCurrentItemSDKFactory,
  tpaGalleryPlayableSDKFactory,
  tpaGalleryNavigationButtonsSDKFactory,
  ownSDKFactory,
);
