import {
  composeSDKFactories,
  assert,
  createCompSchemaValidator,
} from '@wix/editor-elements-corvid-utils';
import {
  clickPropsSDKFactory,
  createElementPropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  IRatingsDisplayProps,
  IRatingsDisplaySDK,
  IRatingsDisplayOwnSDKFactory,
} from '../RatingsDisplay.types';

const roundRating = (number: number) => {
  let pair = `${number}e`.split('e');
  const value = Math.round(+`${pair[0]}e${+pair[1] + 1}`);
  pair = `${value}e`.split('e');
  return +`${pair[0]}e${+pair[1] - 1}`;
};

const ratingsDisplaySDKFactory: IRatingsDisplayOwnSDKFactory = ({
  setProps,
  props,
  metaData,
}) => {
  const ratingValidator = (setterName: string, value?: number | null) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['number', 'nil'],
        warnIfNil: true,
        minimum: 1,
        maximum: 5,
      },
      setterName,
    );

  const numRatingsValidator = (setterName: string, value: number) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['integer', 'nil'],
        warnIfNil: true,
        minimum: 0,
      },
      setterName,
    );

  return {
    get rating() {
      return props.rating;
    },

    set rating(value) {
      if (!ratingValidator('rating', value) || assert.isNil(value)) {
        setProps({ rating: null });
      } else {
        setProps({ rating: roundRating(value) });
      }
    },

    get numRatings() {
      return props.numRatings;
    },

    set numRatings(value) {
      if (!numRatingsValidator('numRatings', value) || assert.isNil(value)) {
        setProps({ numRatings: 0 });
      } else {
        setProps({ numRatings: value });
      }
    },

    get type() {
      return '$w.RatingsDisplay';
    },
  };
};

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<
  IRatingsDisplayProps,
  IRatingsDisplaySDK
>(elementPropsSDKFactory, clickPropsSDKFactory, ratingsDisplaySDKFactory);
