import { colorKeywords } from './colorKeywords';

const HexadecimalLengths = {
  HEXA: { LONG: 9, SHORT: 5 },
  HEX: { LONG: 7, SHORT: 4 },
};

export function isRGBAColor(value: string) {
  const rxValidRgba = /\b([R][G][B][A][(]\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\s*,\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\s*,\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])(\s*,\s*((0\.[0-9]{1})|(1\.0)|(1)|(0)))?[)])/i;
  return rxValidRgba.test(value);
}

export function isRGBColor(value: string) {
  const rxValidRgb = /\b([R][G][B][(]\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\s*,\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\s*,\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])(\s*)[)])/i;
  return rxValidRgb.test(value);
}

export function isHexColor(value: string) {
  const validHexColor = /^#(([0-9|a-f|A-F]){3}){1,2}$/;
  return validHexColor.test(value);
}

export function isHexaColor(value: string) {
  const validHexaColor = /^#([a-f0-9]{8}|[a-f0-9]{4})\b$/gi;
  return validHexaColor.test(value);
}

export function isKeyword(color: string) {
  return colorKeywords[color];
}

export function extractNumericalValues(string: string) {
  return string.replace(/[^\d,]/g, '').split(',');
}

export function isValidRGB(color: string) {
  return isRGBColor(color) || isHexColor(color) || isKeyword(color);
}

export function isValidRGBA(color: string) {
  return isRGBAColor(color) || isHexaColor(color);
}

export function isValidRGBOrRGBA(color: string) {
  return isValidRGBA(color) || isValidRGB(color);
}

export function rgbaToRGBCast(color: string) {
  if (isHexaColor(color)) {
    if (color.length === HexadecimalLengths.HEXA.LONG) {
      return color.slice(0, HexadecimalLengths.HEX.LONG);
    }

    if (color.length === HexadecimalLengths.HEXA.SHORT) {
      return color.slice(0, HexadecimalLengths.HEX.SHORT);
    }
  }

  if (isRGBAColor(color)) {
    const [red, green, blue] = extractNumericalValues(color);
    return `rgb(${red}, ${green}, ${blue})`;
  }

  return color;
}
