import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { reportError, withValidation } from '@wix/editor-elements-corvid-utils';
import { FillLayersProps } from '@wix/thunderbolt-components';
import { createMediaSrc } from '../media/mediaSrcHandler';
import * as mediaItemUtils from '../media/mediaItemUtils';
import {
  getFullMediaData,
  getMediaDataFromSrc,
} from '../media/backgroundUtils';

export interface IBackgroundPropsSDKProps {
  fillLayers?: FillLayersProps;
}

export interface IBackgroundPropsSDK {
  background: { src: string };
}

const _backgroundPropsSDKFactory: CorvidSDKPropsFactory<
  IBackgroundPropsSDKProps,
  IBackgroundPropsSDK
> = ({ setProps, props, metaData }) => {
  return {
    get background() {
      return {
        get src() {
          const { fillLayers } = props;

          if (fillLayers?.video?.videoInfo?.videoId) {
            const { videoInfo } = fillLayers.video;

            const mediaItemUri = createMediaSrc({
              mediaId: videoInfo.videoId,
              type: mediaItemUtils.types.VIDEO,
              title: fillLayers.video.posterImageInfo.title,
              width: videoInfo.videoWidth,
              height: videoInfo.videoHeight,
              // @ts-ignore
              posterId: videoInfo?.generatedPosters.length
                ? // @ts-ignore
                  videoInfo.generatedPosters[0]
                : '',
            });
            if (mediaItemUri.error) {
              return '';
            }

            return mediaItemUri.item || '';
          }

          if (fillLayers && fillLayers.image) {
            const mediaItemUri = createMediaSrc({
              mediaId: fillLayers.image.uri,
              type: mediaItemUtils.types.IMAGE,
              width: fillLayers.image.width,
              height: fillLayers.image.height,
              title: fillLayers.image.title,
            });
            if (mediaItemUri.error) {
              return '';
            }
            return mediaItemUri.item || '';
          }

          return '';
        },

        set src(newSrc: string) {
          const { fillLayers } = props;
          if (!newSrc) {
            // clear the background fillLayers
            setProps({
              fillLayers: {
                containerId: metaData.compId,
              },
            });
            return;
          }

          const mediaData = getMediaDataFromSrc(newSrc);
          if (!mediaData) {
            reportError(
              `The "src" property cannot be set to "${newSrc}". It must be a valid URL starting with "http://", "https://", or "wix:image://, or a valid video URL starting with "wix:video://".`,
            );

            return;
          }

          if (mediaData.type === 'WixVideo') {
            getFullMediaData(mediaData, fullMediaRefData => {
              if (!fullMediaRefData) {
                return;
              }
              console.log('setProps');
              setProps({
                fillLayers: {
                  containerId: metaData.compId,
                  video: {
                    ...(fillLayers ? fillLayers.video : {}),
                    ...fullMediaRefData,
                    posterImageInfo: {
                      containerId: metaData.compId,
                      title: mediaData.posterImageRef?.title,
                      ...fullMediaRefData.posterImageInfo,
                    },
                    videoInfo: {
                      ...fullMediaRefData.mediaObject,
                      containerId: metaData.compId,
                    },
                  },
                },
              });
            });

            setProps({
              fillLayers: {
                containerId: metaData.compId,
                image: {
                  uri: mediaData.mediaId || '',
                  displayMode: 'fill',
                  containerId: metaData.compId,
                  name: '',
                  width: mediaData.posterImageRef?.width || 0,
                  height: mediaData.posterImageRef?.height || 0,
                  alt: '',
                  ...mediaData.posterImageRef,
                },
              },
            });
          } else {
            setProps({
              fillLayers: {
                containerId: metaData.compId,
                image: {
                  ...mediaData,
                  uri: mediaData.mediaId || '',
                  displayMode: 'fill',
                  containerId: metaData.compId,
                  name: '',
                  width: mediaData.width || 0,
                  height: mediaData.height || 0,
                  alt: '',
                },
              },
            });
          }
        },
      };
    },
  };
};

export const backgroundPropsSDKFactory = withValidation(
  _backgroundPropsSDKFactory,
  {
    type: ['object'],
    properties: {
      background: {
        type: ['object'],
        properties: {
          src: {
            type: ['string', 'nil'],
            warnIfNil: true,
          },
        },
      },
    },
  },
);
