import { CustomValidator, ValidationData } from './inputValidationTypes';
import { INITIAL_VALIDATION_DATA } from './validityUtils';

const validationDataMap: { [compId: string]: ValidationData } = {};
const customValidatorMap: { [compId: string]: CustomValidator } = {};

export const getValidationData = (compId: string): ValidationData => {
  return validationDataMap[compId] || { ...INITIAL_VALIDATION_DATA };
};

export const setValidationData = (
  compId: string,
  validationData: ValidationData,
) => {
  validationDataMap[compId] = validationData;
};

export const getCustomValidator = (compId: string): CustomValidator => {
  return customValidatorMap[compId] || (() => {});
};

export const setCustomValidator = (
  compId: string,
  customValidator: CustomValidator,
) => {
  customValidatorMap[compId] = customValidator;
};
