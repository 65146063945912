import {
  withValidation,
  composeSDKFactories,
  createCompSchemaValidator,
} from '@wix/editor-elements-corvid-utils';
import {
  IQuickActionBarSDKFactory,
  IQuickActionBarProps,
  IQuickActionBarSDK,
  OnItemClickedCorvidEvent,
} from '../QuickActionBar.types';
import { createElementPropsSDKFactory } from '../../../core/corvid/props-factories';

const _quickActionBarSDKFactory: IQuickActionBarSDKFactory = ({
  setProps,
  props,
  registerEvent,
  metaData,
  getSdkInstance,
  create$w,
}) => {
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );
  return {
    get alignment() {
      return props.alignment;
    },
    set alignment(value) {
      setProps({ alignment: value });
    },
    get colorScheme() {
      return props.colorScheme;
    },
    set colorScheme(value) {
      setProps({ colorScheme: value });
    },
    get invertColorScheme() {
      return props.isColorSchemeInverted;
    },
    set invertColorScheme(shouldInvert) {
      setProps({ isColorSchemeInverted: shouldInvert });
    },
    get showLabels() {
      return !props.hideText;
    },
    set showLabels(shouldShow) {
      setProps({ hideText: !shouldShow });
    },
    onItemClicked(cb) {
      if (!functionValidator(cb, 'onItemClicked')) {
        return getSdkInstance();
      }
      registerEvent<OnItemClickedCorvidEvent>(
        'onQuickActionBarItemClicked',
        event => {
          const $w = create$w();
          cb(event, $w);
        },
      );
    },
  };
};

const quickActionBarSDKFactory = withValidation(_quickActionBarSDKFactory, {
  type: ['object'],
  properties: {
    showLabels: {
      type: ['boolean'],
    },
    alignment: {
      type: ['string'],
      enum: ['left', 'right'],
    },
    colorScheme: {
      type: ['string'],
      enum: ['brand', 'grey', 'black'],
    },
    invertColorScheme: {
      type: ['boolean'],
    },
  },
});

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<
  IQuickActionBarProps,
  any,
  IQuickActionBarSDK
>(elementPropsSDKFactory, quickActionBarSDKFactory);
