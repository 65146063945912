import {
  composeSDKFactories,
  withValidation,
  reportError,
  messages,
} from '@wix/editor-elements-corvid-utils';
import {
  IFileUploaderImperativeActions,
  IFileUploaderOwnSDKFactory,
  IFileUploaderProps,
  IFileUploaderSDK,
} from '../FileUploader.types';
import {
  createRequiredPropsSDKFactory,
  createValidationPropsSDKFactory,
  focusPropsSDKFactory,
  disablePropsSDKFactory,
  createElementPropsSDKFactory,
  changePropsSDKFactory,
  createStylePropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import {
  composeValidators,
  createInputValidator,
  INITIAL_VALIDATION_DATA as initialValidationData,
  InputValidator,
  OnValidateArgs,
  validateUploadDone,
  validateRequiredArray,
  validateFile,
} from '../../../core/corvid/inputUtils';
import {
  FailedUploadResponse,
  getErrorObject,
  getFileDataByType,
  getFileInfo,
  getWixCodeURI,
  isValidFileType,
  startFileUpload,
  SuccessfulUploadResponse,
} from './utils';
import { setUploadServerError } from './uploadServerErrorState';

const fileUploaderValidator: InputValidator<
  IFileUploaderProps,
  IFileUploaderImperativeActions
> = createInputValidator(
  composeValidators<IFileUploaderProps>([
    validateRequiredArray,
    validateFile,
    validateUploadDone,
  ]),
);

const requiredPropsSDKFactory = createRequiredPropsSDKFactory(
  fileUploaderValidator,
);
const validationPropsSDKFactory = createValidationPropsSDKFactory(
  fileUploaderValidator,
);

const stylePropsSDKFactory = createStylePropsSDKFactory({
  BackgroundColor: true,
  BorderColor: true,
  BorderWidth: true,
  BorderRadius: true,
  TextColor: true,
});

const _ownSDKFactory: IFileUploaderOwnSDKFactory = api => {
  const { handlers, props, setProps, compRef, registerEvent, metaData } = api;
  let validationData = initialValidationData;
  let rejectCallback: ((reason: any) => void) | null;

  const rejectCurrentUpload = ({
    reason,
    shouldFailUploadStatus,
  }: {
    reason: any;
    shouldFailUploadStatus: boolean;
  }) => {
    if (shouldFailUploadStatus) {
      setProps({ uploadStatus: 'Failed' });

      fileUploaderValidator.validate({
        viewerSdkAPI: api,
      });
    }

    if (rejectCallback) {
      rejectCallback(reason);
      rejectCallback = null;
    }
  };

  registerEvent('onChange', () => {
    if (!props.value.length) {
      setUploadServerError(metaData.compId, null);
    }
  });

  fileUploaderValidator.onValidate(
    ({
      viewerSdkAPI,
      validationDataResult,
    }: OnValidateArgs<IFileUploaderProps, IFileUploaderImperativeActions>) => {
      validationData = validationDataResult;

      const isInvalidToUpload = Object.entries(validationData.validity).some(
        ([key, value]) => value && key !== 'valid' && key !== 'fileNotUploaded',
      );

      viewerSdkAPI.setProps({
        isInvalidToUpload,
        validationMessage: validationData.validationMessage,
      });
    },
  );

  fileUploaderValidator.validate({
    viewerSdkAPI: api,
    showValidityIndication: false,
  });

  const onSuccessfulUpload = (uploadResponse: SuccessfulUploadResponse) => {
    setProps({ uploadStatus: 'Done' });

    const fileData = getFileDataByType(props.fileType, uploadResponse[0] || {});
    let resolvedValue;
    try {
      const url = getWixCodeURI(fileData);
      resolvedValue = getFileInfo(fileData.media_type, url);
    } catch (error) {
      if (error.message.startsWith('Unknown media_type')) {
        resolvedValue = fileData;
      } else {
        return rejectCurrentUpload({
          reason: error,
          shouldFailUploadStatus: true,
        });
      }
    }

    fileUploaderValidator.validate({
      viewerSdkAPI: api,
    });
    return resolvedValue;
  };

  const onFailedUpload = (uploadResponse: FailedUploadResponse) => {
    const returnedError = {
      errorCode: uploadResponse.error_code,
      errorDescription: uploadResponse.error_description,
    };

    setUploadServerError(metaData.compId, returnedError.errorDescription);

    return rejectCurrentUpload({
      reason: returnedError,
      shouldFailUploadStatus: true,
    });
  };

  const sdkProps = {
    get buttonLabel() {
      return props.buttonLabel;
    },
    set buttonLabel(_buttonLabel) {
      const buttonLabel = _buttonLabel || '';
      setProps({ buttonLabel });
    },
    get fileType() {
      return props.fileType;
    },
    set fileType(_fileType) {
      const fileType =
        _fileType.charAt(0).toUpperCase() + _fileType.slice(1).toLowerCase();
      setProps({ fileType });
      fileUploaderValidator.validate({
        viewerSdkAPI: api,
        showValidityIndication: true,
      });
    },
    get value() {
      return props.value;
    },
    set value(_value) {
      reportError(
        messages.onlyGetter({
          compType: 'UploadButton',
          propertyName: 'value',
        }),
      );
    },

    async startUpload() {
      return new Promise(async (resolve, reject) => {
        fileUploaderValidator.validate({
          viewerSdkAPI: api,
        });
        if (!rejectCallback) {
          rejectCallback = reject;
        } else {
          return reject(getErrorObject(validationData));
        }

        const fileList = await compRef.getFiles();
        if (!fileList.length || props.isInvalidToUpload) {
          return rejectCurrentUpload({
            reason: getErrorObject(validationData),
            shouldFailUploadStatus: false,
          });
        }
        setProps({ uploadStatus: 'Started' });
        try {
          return startFileUpload({
            resolve,
            fileType: props.fileType,
            handlers,
            fileList,
            onFailedUpload,
            onSuccessfulUpload,
          });
        } catch (error) {
          return rejectCurrentUpload({
            reason: error,
            shouldFailUploadStatus: true,
          });
        }
      }).finally(() => {
        rejectCallback = null;
      });
    },

    reset() {
      setProps({
        value: [],
        uploadStatus: 'Not_Started',
        shouldShowValidityIndication: false,
      });
      validationData = initialValidationData;
      setUploadServerError(metaData.compId, null);
      compRef.resetFiles();

      const rejectReason = {
        errorCode: -1,
        errorDescription: 'Upload Reset',
      };

      rejectCurrentUpload({
        reason: rejectReason,
        shouldFailUploadStatus: false,
      });

      fileUploaderValidator.validate({
        viewerSdkAPI: api,
      });
    },

    get type() {
      return '$w.UploadButton';
    },

    toJSON() {
      const { required } = props;
      const { value, buttonLabel, fileType, startUpload, reset } = sdkProps;
      return {
        ...toJSONBase(metaData),
        type: '$w.UploadButton',
        required,
        value,
        buttonLabel,
        fileType,
        startUpload,
        reset,
      };
    },
  };

  return sdkProps;
};

const customRules = {
  fileType: [isValidFileType],
};

const ownSDKFactory = withValidation(
  _ownSDKFactory,
  {
    type: ['object'],
    properties: {
      buttonLabel: {
        type: ['string', 'nil'],
        warnIfNil: true,
      },
      fileType: {
        type: ['string'],
      },
    },
  },
  customRules,
);
const elementPropsSDKFactory = createElementPropsSDKFactory();
export const sdk = composeSDKFactories<IFileUploaderProps, IFileUploaderSDK>(
  elementPropsSDKFactory,
  requiredPropsSDKFactory,
  validationPropsSDKFactory,
  focusPropsSDKFactory,
  changePropsSDKFactory,
  disablePropsSDKFactory,
  stylePropsSDKFactory,
  ownSDKFactory,
);
