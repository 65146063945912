import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { withValidation } from '@wix/editor-elements-corvid-utils';
import {
  getCustomValidityMessage,
  getValidationData,
  setCustomValidator,
  setValidationData,
  InputValidator,
  OnValidateArgs,
  CustomValidator,
  ValidityObject,
} from '../inputUtils';

export interface IValidationPropSDKProps {
  // isValid is used for some, but not all, input components
  isValid?: boolean;
  shouldShowValidityIndication: boolean;
}

export interface IValidationPropsImperativeActions {
  setCustomValidity: (message: string) => void;
}

export interface IValidationPropsSDKActions<TProps> {
  validateValueAndShowIndication: (propsOverrides?: Partial<TProps>) => void;
  hideValidityIndication: () => void;
}

export interface IValidationPropSDK {
  valid: boolean;
  validationMessage: string;
  validity: ValidityObject;
  onCustomValidation: (validator: CustomValidator) => void;
  updateValidityIndication: () => void;
  resetValidityIndication: () => void;
}

export const createValidationPropsSDKFactory = <
  TProps extends IValidationPropSDKProps,
  TCompRef extends IValidationPropsImperativeActions
>(
  inputValidator: InputValidator<TProps, TCompRef>,
) => {
  inputValidator.onValidate(
    ({
      viewerSdkAPI,
      validationDataResult,
      showValidityIndication,
    }: OnValidateArgs<TProps, TCompRef>) => {
      const { setProps, compRef, metaData } = viewerSdkAPI;
      const prevValidationData = getValidationData(metaData.compId);
      const prevCustomValidityMessage = getCustomValidityMessage(
        prevValidationData,
      );
      const customValidityMessage = getCustomValidityMessage(
        validationDataResult,
      );

      if (prevCustomValidityMessage !== customValidityMessage) {
        compRef.setCustomValidity(customValidityMessage);
      }
      const compProps = {
        isValid: validationDataResult.validity.valid,
        ...(showValidityIndication && { shouldShowValidityIndication: true }),
      };

      setValidationData(metaData.compId, validationDataResult);
      setProps(compProps as Partial<TProps>);
    },
  );

  const _validationPropsSDKFactory: CorvidSDKPropsFactory<
    TProps,
    IValidationPropSDK,
    TCompRef
  > = api => {
    const { setProps, props, registerEvent, metaData } = api;

    // We get props override because sometimes props are not updated the way we expect them to be.
    // For example - Checkbox's onChange from the controller updatesProps with the latest checked value. We expect to have these updated props here in validateValueAndShowIndication
    // That is called right after onChange, but in Safari that is not the case. For now we are passing props overrides.

    // Ticket - https://jira.wixpress.com/browse/PLAT-934. PR to revert after the fix (Don't revert the onChange radioGroup changes!) - https://github.com/wix-private/editor-elements/pull/2584
    registerEvent(
      'validateValueAndShowIndication',
      (propsOverrides?: Partial<TProps>) => {
        const updatedProps = {
          ...props,
          ...propsOverrides,
        };

        const viewerSdkAPI = {
          ...api,
          props: updatedProps,
        };

        inputValidator.validate({
          viewerSdkAPI,
          showValidityIndication: true,
        });
      },
    );
    registerEvent('hideValidityIndication', () => {
      setProps({ shouldShowValidityIndication: false } as Partial<TProps>);
    });

    inputValidator.validate({
      viewerSdkAPI: api,
      showValidityIndication: false,
    });

    return {
      get valid() {
        return getValidationData(metaData.compId).validity.valid;
      },
      get validationMessage() {
        return getValidationData(metaData.compId).validationMessage;
      },
      get validity() {
        return getValidationData(metaData.compId).validity;
      },
      onCustomValidation(validator: CustomValidator) {
        setCustomValidator(metaData.compId, validator);
        inputValidator.validate({
          viewerSdkAPI: api,
        });
      },
      updateValidityIndication() {
        setProps({ shouldShowValidityIndication: true } as Partial<TProps>);
      },
      resetValidityIndication() {
        setProps({ shouldShowValidityIndication: false } as Partial<TProps>);
      },
    };
  };

  return withValidation(_validationPropsSDKFactory, {
    type: ['object'],
    properties: {
      onCustomValidation: {
        type: ['function'],
        args: [{ type: ['function'] }],
      },
    },
  });
};
