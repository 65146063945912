import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { TinyMenuSdkData } from '@wix/thunderbolt-components';
import {
  ITinyMenuProps,
  ITinyMenuSDK,
  ITinyMenuOwnSDKFactory,
} from '../TinyMenu.types';
import {
  createElementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import { createAccountNavBarSdkFactory } from '../../LoginSocialBar/corvid/LoginSocialBar.corvid';

type AccountNavBarApi = Parameters<
  ReturnType<typeof createAccountNavBarSdkFactory>
>[0];
type AccountNavBarSetProps = AccountNavBarApi['setProps'];
type AccountNavBarSetPropsParam = Parameters<AccountNavBarSetProps>[0];

const tinyMenuSDKFactory: ITinyMenuOwnSDKFactory = api => {
  const { props, sdkData, setProps, metaData } = api;
  const accountNavBarSetProps: AccountNavBarSetProps = value => {
    if (!sdkData.members) {
      return;
    }

    const mapPropsToMembers = (newProps: AccountNavBarSetPropsParam) => ({
      members: {
        ...props.members,
        ...newProps,
      },
    });

    const newProps =
      Promise.resolve(value) === value // Value is a Promise
        ? value.then(mapPropsToMembers)
        : mapPropsToMembers(value);

    setProps(newProps);
  };

  const accountNavBar = createAccountNavBarSdkFactory({ isMobileOnly: true })({
    ...api,
    props: props.members,
    sdkData: sdkData.members ?? {
      menuItems: [],
      navBarItems: [],
    },
    setProps: accountNavBarSetProps,
  } as AccountNavBarApi);

  return {
    get accountNavBar() {
      return accountNavBar;
    },
    get type() {
      return '$w.MobileMenu';
    },
    toJSON() {
      return { ...toJSONBase(metaData), type: '$w.MobileMenu' };
    },
  };
};

const elementPropsSDKFactory = createElementPropsSDKFactory();
export const sdk = composeSDKFactories<
  ITinyMenuProps,
  ITinyMenuSDK,
  TinyMenuSdkData
>(elementPropsSDKFactory, tinyMenuSDKFactory);
