import {
  messageTemplates,
  reportError,
  reportWarning,
} from '@wix/editor-elements-corvid-utils';
import { CorvidSDKApi } from '@wix/editor-elements-types';
import { isValidRGBOrRGBA, isValidRGBA, validatePixel } from './styleUtils';

export const getColorValidator = (
  propertyName: string,
  cssProperty: string,
  rgb?: boolean,
) => (color: string, api: CorvidSDKApi<object, object>) => {
  if (!isValidRGBOrRGBA(color)) {
    if (rgb) {
      reportError(
        messageTemplates.error_invalid_css_value_multiple_expected_formats({
          propertyName,
          cssProperty,
          infoLink: `https://www.wix.com/corvid/new-reference/$w/style/${propertyName.toLowerCase()}`,
          compName: api.metaData.compId,
          exampleFormats: '"red", "#FF0000", or "rgb(225, 0, 0)"',
        }),
      );
      return false;
    }

    reportError(
      messageTemplates.error_invalid_css_value_multiple_expected_formats({
        propertyName,
        cssProperty,
        infoLink: `https://www.wix.com/corvid/new-reference/$w/style/${propertyName.toLowerCase()}`,
        compName: api.metaData.compId,
        exampleFormats:
          '"red", "#FF0000", "#FF000000", "rgb(225, 0, 0)" or "rgba(225, 0, 0, 0)"',
      }),
    );

    return false;
  }

  return true;
};

export const getPixelValidator = (
  propertyName: string,
  cssProperty: string,
) => (unit: string, api: CorvidSDKApi<object, object>) => {
  if (!validatePixel(unit)) {
    reportError(
      messageTemplates.error_invalid_css_value({
        propertyName,
        cssProperty,
        infoLink: `https://www.wix.com/corvid/new-reference/$w/style/${propertyName.toLowerCase()}`,
        compName: api.metaData.compId,
        exampleFormat: '1px',
      }),
    );

    return false;
  }

  return true;
};

export const getRGBAWarning = (propertyName: string) => (
  color: string,
  api: CorvidSDKApi<object, object>,
) => {
  if (isValidRGBA(color)) {
    reportWarning(
      messageTemplates.warning_color_casting_performed({
        propertyName,
        compName: api.metaData.compId,
        infoLink: `https://www.wix.com/corvid/new-reference/$w/style/${propertyName.toLowerCase()}`,
      }),
    );
  }

  return true;
};
