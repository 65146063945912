const uploadServerErrorMap: { [compId: string]: string | null } = {};

export const getUploadServerError = (compId: string): string | null => {
  return uploadServerErrorMap[compId];
};

export const setUploadServerError = (
  compId: string,
  serverError: string | null,
) => {
  uploadServerErrorMap[compId] = serverError;
};
