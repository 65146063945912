import {
  composeSDKFactories,
  createElementPropsSDKFactory,
} from '@wix/editor-elements-corvid-utils';
import { IFallbackProps } from './Fallback.types';

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<IFallbackProps, any, any>(
  elementPropsSDKFactory,
);
