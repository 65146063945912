import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { CorvidTypes } from '@wix/editor-elements-types';
import { tpaGalleryPropsSDKFactory } from '../../TPAGallery/corvid/TPAGallerySDK';
import {
  TPAGallerySDK,
  TPAGalleryProps,
  TPAGalleryCapabilitiesSDK,
} from '../../TPAGallery/TPAGallery.types';

const ownSDKFactory: CorvidTypes.CorvidSDKFactory<TPAGalleryCapabilitiesSDK> = () => ({
  get galleryCapabilities() {
    return {
      isPlayable: false,
      hasCurrentItem: false,
      hasNavigationButtons: false,
      supportsAllMediaTypes: false,
      isAnimatable: true,
    };
  },
});

export const sdk = composeSDKFactories<TPAGalleryProps, TPAGallerySDK>(
  tpaGalleryPropsSDKFactory,
  ownSDKFactory,
);
