import { UploadStatus } from '../../../components/FileUploader/FileUploader.types';
import { getUploadServerError } from '../../../components/FileUploader/corvid/uploadServerErrorState';
import { ValidationData } from './inputValidationTypes';
import {
  addErrorToValidationDataAndKeepMessage,
  getValidationMessage,
} from './validityUtils';

const getFileMissingMessage = (
  uploadStatus: UploadStatus,
  compId: string,
): string => {
  if (uploadStatus === 'Failed') {
    return (
      getUploadServerError(compId) || getValidationMessage('fileNotUploaded')
    );
  }

  return getValidationMessage('fileNotUploaded');
};

export const validateUploadDone = (
  props: {
    uploadStatus: UploadStatus;
    required: boolean;
    id: string;
  },
  validationData: ValidationData,
): ValidationData => {
  const { required, uploadStatus, id } = props;
  const isAlreadyInvalid = !validationData.validity.valid;

  if (!required || isAlreadyInvalid) {
    return validationData;
  }

  return uploadStatus === 'Done'
    ? validationData
    : addErrorToValidationDataAndKeepMessage(
        validationData,
        'fileNotUploaded',
        getFileMissingMessage(uploadStatus, id),
      );
};
