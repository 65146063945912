const intermediateValueMap: { [compId: string]: string | null } = {};

// We hold an intermediate state for cases when a new (valid) value is set before options are changed.
export const getIntermediateValue = (compId: string): string | null => {
  return intermediateValueMap[compId];
};

export const setIntermediateValue = (compId: string, value: string | null) => {
  intermediateValueMap[compId] = value;
};
