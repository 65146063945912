import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { withValidation } from '@wix/editor-elements-corvid-utils';
import { getScopedVar } from './styleUtils';
import { getColorValidator } from './validation';
import { cssVars } from './constants';
import { IStylePropsSDKOptions } from './types';

export type IForegroundColorSDK = {
  foregroundColor: string;
};

export const createForegroundColorPropsSDKFactory = (
  options?: Partial<IStylePropsSDKOptions>,
) => {
  const cssRule = getScopedVar({
    name: cssVars.foregroundColor,
    prefix: options?.prefix,
  });

  const _foregroundColorPropsSDKFactory: CorvidSDKPropsFactory<
    IForegroundColorSDK,
    { foregroundColor?: string | null }
  > = ({ setStyles, sdkData }) => {
    let foregroundColorState: string =
      sdkData?.initialSdkStyles?.foregroundColor;
    return {
      set foregroundColor(foregroundColor) {
        foregroundColorState = foregroundColor;
        setStyles({
          [cssRule]: foregroundColor,
        });
      },
      get foregroundColor() {
        return foregroundColorState;
      },
    };
  };
  return withValidation(
    _foregroundColorPropsSDKFactory,
    {
      type: ['object'],
      properties: {
        foregroundColor: {
          type: ['string', 'nil'],
        },
      },
    },
    {
      foregroundColor: [getColorValidator('foregroundColor', 'rgbaColor')],
    },
  );
};
